<template>
  <div class="req-temp-container">
    <vs-card class="mt-6">
      <vs-row class="mb-5">
        <vs-col vs-w="7">
          <S3ImageGenerator
            v-if="introducerImage"
            :document="introducerImage"
            :is-base64="false"
            :key="introducerImage"
            :custom-class="'width-100'"
          />
          <img v-else :src="defaultLogo" width="100px" />
        </vs-col>
        <vs-col vs-w="5" class="text-right">
          <vs-button
            flat
            v-round
            class="only-border-btn mr-3"
            size="medium"
            type="border"
            v-if="linkRequest.status == 'Pending'"
            @click="updateStatus('Rejected')"
          >
            Decline request
          </vs-button>
          <vs-button
            v-round
            size="medium"
            v-if="linkRequest.status == 'Pending'"
            @click="updateStatus('Connected')"
          >
            Accept request
          </vs-button>
          <vs-button
            flat
            v-round
            class="only-border-btn mr-3"
            size="medium"
            type="border"
            v-if="linkRequest.status == 'Connected'"
            @click="deleteLink"
          >
            Remove link
          </vs-button>
        </vs-col>
      </vs-row>

      <div class="mt-5 mb-4" v-if="linkRequest">
        <h3>{{ linkRequest.introducerId.platformName || linkRequest.introducerId.name }}</h3>
        <p>
          {{ linkRequest.introducerId.platformDescription || '' }}
        </p>
      </div>

      <div v-if="linkRequest &&linkRequest.introducerId.website">
        <p class="mb-2">Website</p>
        <h4>{{ linkRequest.introducerId.website }}</h4>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import S3ImageGenerator from "../../components/S3ImageGenerator";

export default {
  components: {
    S3ImageGenerator
  },
  data() {
    return {
      linkRequest: "",
      id: "",
      introducerImage: "",
      appName: process.env.VUE_APP_NAME,
      defaultLogo: this.appName ==="FlipPay" ? require('@/assets/images/logo/flipmoney.png') : require('@/assets/images/logo/rellopay-logo.svg')
    };
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId
    },
  },
  metaInfo() {
    return {
      pageTitle: "Custom Ttitle",
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    this.merchantId =
      JSON.parse(localStorage.user).userType == "admin"
        ? JSON.parse(localStorage.user).merchantId
        : JSON.parse(localStorage.user).partner.merchantId;
    this.getLinkRequestById();
  },
  methods: {
    ...mapActions("linkRequest", [
      "updateLinkRequestById",
      "fetchRequestDetailById",
    ]),

    async getLinkRequestById() {
      await this.fetchRequestDetailById(this.id).then((res) => {
        this.linkRequest = res.data.data;
        if(this.linkRequest.introducerId.logo){
          this.introducerImage = this.linkRequest.introducerId.logo
        }
      }).catch(err=> {
        this.$vs.notify({
          title: "Error",
          text: err.response.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: 'top-right'
        });
        if(err.response.status === 404) {
          this.$router.push({name:'linked-system'});
        }
      });
    },
    async updateStatus(status) {
      this.$vs.loading();
      let obj = {
        id: this.id,
        payload: {
          merchantId: this.partnerId,
          introducerId: this.linkRequest.introducerId._id,
          status: status,
        },
      };
      if(status === 'Rejected'){
        obj.payload.deleted = true;
      }
      await this.updateLinkRequestById(obj)
        .then((res) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: res.data.title,
            text: res.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          if (status == "Connected") {
            this.getLinkRequestById();
          } else {
            this.goBack();
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Request template",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    async deleteLink() {
      this.$vs.loading();
      let obj = {
        id: this.id,
        payload: {
          merchantId: this.partnerId,
          introducerId: this.linkRequest.introducerId._id,
          deleted: true,
        },
      };
      this.updateLinkRequestById(obj).then((res) => {
        this.$vs.loading.close();
          this.$vs.notify({
            title: res.data.title,
            text: 'Link removed successfully',
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
        this.goBack();
      })
      .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Request template",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    goBack() {
      this.$router.push({
        name: "linked-system",
      });
    },
  },
};
</script>
